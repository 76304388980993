
        import * as module0 from '$components/button.css';import * as module1 from '$components/contact.css';import * as module2 from '$components/contact.js';import * as module3 from '$components/hero.css';import * as module4 from '$components/logo.css';import * as module5 from '$components/text.css'
        const modules = {
            "button.css": module0,
            "contact.css": module1,
            "contact.js": module2,
            "hero.css": module3,
            "logo.css": module4,
            "text.css": module5,
        };
        export default modules;
      